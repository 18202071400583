<template>
  <div>
    <div>
      <action-header
          v-if="!noHeader"
          :is-loading="!deliveryRequest"
          :actions-model="headerModel"
          :is-pin="false"
          :page="page"
      />

      <div
          v-if="deliveryRequest && deliveryRequest.deliveryServiceStatus"
          class="q-pa-md text-center bg-red-5"
      >
        <strong>{{ deliveryRequest.deliveryServiceStatus }}</strong>
      </div>

      <div class="q-pa-md">
        <q-legend
            class="q-mb-sm"
            :label="$t('Shipping details')"
        />

        <c-profile
            :is-loading="!deliveryRequest"
            :disabled="isDisabledFields"
            :model="(deliveryRequest && deliveryRequest._embedded.recipient) || undefined"
            :phone-model="(deliveryRequest && deliveryRequest._embedded.recipientPhone) || undefined"
            @profile="updateProfile"
            @phone="updatePhone"
        />

        <q-separator class="q-my-md"/>

        <div class="row items-center q-mb-md">
          <q-legend :label="$t('Address')"/>

          <q-btn-group class="q-ml-sm">
            <q-btn
                v-if="!disabledDomestic"
                size="sm"
                :text-color="isInternational ? 'dark' : 'white'"
                :color="isInternational ? 'grey-3' : 'light-blue-9'"
                :icon="isInternational ? undefined : 'done'"
                :disable="false"
                :label="$t('Domestic')"
                @click="isInternational = false"
            />

            <q-btn
                size="sm"
                :text-color="isInternational ? 'white' : 'dark'"
                :color="isInternational ? 'light-blue-9' : 'grey-3'"
                :icon="isInternational ? 'done' : undefined"
                :disable="false"
                :label="$t('International')"
                @click="isInternational = true"
            />
          </q-btn-group>

          <q-space/>

        </div>

        <c-address
            ref="address"
            :is-loading="!deliveryRequest"
            :type="addressType"
            :disabled="isDisabledFields"
            :model="(deliveryRequest && deliveryRequest._embedded.recipientAddress) || undefined"
            @address="updateRecipientAddress"
        >
          <base-address
              :is-loading="!deliveryRequest"
              :address="(deliveryRequest && deliveryRequest._embedded.recipientAddress) || undefined"
              :disabled="isDisabledFields"
              @change="updateRecipientAddress"
          />
        </c-address>

        <q-separator class="q-my-md"/>

        <q-legend
            class="q-mb-sm"
            :label="$t('Transportation details')"
        />

        <delivery-request-form
            :is-loading="!deliveryRequest"
            :locality="locality"
            :is-locked="isLockedDR"
            @locality="handleLocality"
            @handle-delivery-services-ids="handleDeliveryServicesIds"
        />
      </div>

      <div
          v-if="window"
          class="text-center q-pa-sm border-top"
      >
        <q-btn
            color="dark"
            text-color="white"
            size="sm"
            :label="$t('Close')"
            no-caps
            class="q-mr-sm"
            unelevated
            @click="handleBack"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="$t('Save')"
            :disable="isDisabledFields"
            no-caps
            unelevated
            @click="makeSaveDeliveryRequest"
        />

        <q-btn
            color="dark"
            text-color="white"
            size="sm"
            :label="$t('Refresh')"
            no-caps
            unelevated
            @click="refresh"
        />
      </div>
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="!window && hasDRChange"
        :disabled="isDisabledFields"
        @back="handleDiscard"
        @save="makeSaveDeliveryRequest"
    />


    <delivery-request-dimensions ref="exportModal"/>

    <q-dialog v-model="historyModal">
      <q-card style="min-width: 65vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section v-if="deliveryRequest && deliveryRequest.id">
          <history
              v-if="deliveryRequest"
              :id="deliveryRequest.id"
          />
        </q-card-section>

        <q-card-section
            v-else
            class="alert alert-warning"
        >
          {{ $t('Shipping Request not exist!') }}
        </q-card-section>
      </q-card>
    </q-dialog>

    <d-r-products-modal
        ref="productsModal"
        @change="handlePlaces"
    />

    <q-dialog v-model="calculatorModal">
      <q-card style="min-width: 95vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Calculator') }}
          </div>

          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section
            v-if="calculatorError"
            class="alert alert-warning"
        >
          {{ $t(calculatorError) }}
        </q-card-section>

        <q-card-section v-else>
          <!--          :sender="deliveryRequest._embedded.sender"-->
          <!--          :sender-address=" deliveryRequest._embedded.senderAddress"-->
          <!--          :locality="deliveryRequest._embedded.recipientLocality"-->
          <!--          :postcode="deliveryRequest._embedded.recipientAddress.postcode"-->
          <!--          :estimated-cost="parseInt(deliveryRequest.estimatedCost)"-->
          <!--          :payment="parseInt(deliveryRequest.payment)"-->
          <d-s-calculator
              ref="calculator"
              :delivery-request="deliveryRequest"
              :locality="locality"
              :weight="Number(deliveryRequest.weight) ? deliveryRequest.weight : 1"
              :width="deliveryRequest.dimensions && deliveryRequest.dimensions.x ? deliveryRequest.dimensions.x : 1"
              :height="deliveryRequest.dimensions && deliveryRequest.dimensions.y ? deliveryRequest.dimensions.y : 1"
              :length="deliveryRequest.dimensions && deliveryRequest.dimensions.z ? deliveryRequest.dimensions.z : 1"
              :disabled="isDisabledFields"
              @rate="updateRate"
              @on-service-point-change="updateServicePoint"
              @locality-change="handleLocalityChange"
              @sender-change="handleUpdateSender"
              @update-dimensions="updateDimensions"
          />
        </q-card-section>
      </q-card>
    </q-dialog>


    <chat-modal ref="chatModal"/>

    <shipment-task-modal ref="shipmentTaskModal"/>

    <tracking-number-modal ref="trackingNumberModal" @submit="handleTrackingNumber"/>

    <delivery-request-services-modal ref="deliveryRequestModal"/>

    <document-template-modal
        ref="documentModal"
        :entity-class="'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest'"
    />

    <eav-modal ref="eavModal" @submit="handleEAVSubmit">
      <form-builder :schema="schema"/>
    </eav-modal>

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'
import History from './../../components/history/history'
import DeliveryRequestDimensions from '../../components/delivery-services/DeliveryRequestDimensions'
import BaseAddress from './../../components/clients/BaseAddress'
import DeliveryRequestForm from './../../components/clients/DeliveryRequestForm'
import ChatModal from '../../components/chat/ChatModal'
import DRProductsModal from '../../components/modals/DRProductsModal.vue'
import DocumentTemplateModal from '../../components/modals/DocumentTemplateModal.vue'
import TrackingNumberModal from '../../components/modals/TrackingNumberModal.vue'
import ShipmentTaskModal from '../../components/modals/ShipmentTaskModal.vue'
import EavModal from '../../components/modals/EavModal.vue'
import DeliveryRequestServicesModal from '../../components/modals/DeliveryRequestServicesModal.vue'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Helpers
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'
import { getRouteOptions } from '@/apps/app/helpers/helpers'

export default {
  name: 'DeliveryRequestLegacy',
  emits: ['close', 'submit'],
  components: {
    DeliveryRequestServicesModal,
    DeliveryRequestDimensions,
    ActionHeader,
    CProfile: defineAsyncComponent(() => import('./../../components/clients/Profile.vue')),
    CAddress: defineAsyncComponent(() => import('../../components/clients/Address.vue')),
    DSCalculator: defineAsyncComponent(() => import('../../components/delivery-services/Calculator.vue')),
    History,
    BaseAddress,
    DeliveryRequestForm,
    ChatModal,
    DRProductsModal,
    DocumentTemplateModal,
    TrackingNumberModal,
    ShipmentTaskModal,
    EavModal
  },
  mixins: [
    TableMixin
  ],
  props: {
    noHeader: {
      type: Boolean,
      default () {
        return false
      }
    },
    window: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isSaveLoading: false,
      calculatorModal: false,
      historyModal: false,
      statuses: deliveryRequestStatusMatrix,
      isCourier: true,
      isOpenMobileMenu: false,
      deliveryServicesIds: [],
      isManuallyLocked: false,
      isInternational: this.$utils.getIsInternationalAddress(),
      disabledDomestic: false,
      simple: false,
      header: '',
      prefix: '',
      beta: 'dark'
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'deliveryServices',
      'rates',
      'deliveryRequest',
      'history',
      'loadingNow',
      'places',
      'servicePoints',
      'totalServicePointsNumber',
      'settings',
      'senders',
      'servicePointsPage',
      'servicePointsTotalPages',
      'ratesPage',
      'ratesTotalPages',
      'deliveryRequests',
      'lockedDeliveryRequests',
      'hasDRChange',
      'hasBaseAccess',
      'task',
      'routeShortcuts'
    ]),
    note () {
      if (this.routeShortcuts.find(({ route }) => route === this.$route.fullPath)) {
        return {
          color: 'positive',
          label: 'Unpin',
          icon: 'push_pin'
        }
      }
      return {
        color: 'dark',
        label: 'Pin',
        icon: 'push_pin'
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.deliveryRequest.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {
                  this.updateDeliveryRequest({ extId })
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.deliveryRequest && this.deliveryRequest.id,
        name: this.deliveryRequest && this.deliveryRequest.id
            ? this.$t('Shipment')
            : this.$t('New shipment')
      }
    },
    addressType () {
      let rightTab = this.simple ? 'domestic' : 'international'
      let leftTab = this.simple ? 'international' : 'domestic'

      return this.isInternational
          ? rightTab
          : leftTab
    },
    locality () {
      return this.deliveryRequest && this.deliveryRequest._embedded.recipientAddress._embedded.locality
    },
    outSideFilters () {
      return [
        {
          type: 'in',
          field: 'deliveryService',
          values: this.deliveryServicesIds
        }
      ]
    },
    calculatorError () {
      if (!this.deliveryRequest) {
        return 'Shipping Request data is required!'
      }

      return ''
    },
    headerModel () {
      if (!this.deliveryRequest) {
        return []
      }

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        editableFields: false,
        onClick: (state) => {
          this.updateDeliveryRequest({ state })

          if (this.deliveryRequest.id) {
            this.saveDeliveryRequestByPayload({ data: { state }, id: this.deliveryRequest.id })
          }
        },
        value: { id: 'pending_queued', title: 'Pending Queued' },
        options: this.statuses
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'col-12 col-sm-2 text-right q-pb-sm',
        options: [
          option
        ]
      }

      if (this.deliveryRequest && this.deliveryRequest.state !== option.value.id) {
        this.statuses.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.deliveryRequest.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      let deliveryService = this.deliveryRequest &&
          this.deliveryRequest.id &&
          this.deliveryRequest._embedded &&
          this.deliveryRequest._embedded.deliveryService &&
          this.deliveryRequest._embedded.deliveryService.id

      if (!deliveryService && this.deliveryRequest.id && this.deliveryRequest._embedded.rate) {
        const parts = this.deliveryRequest._embedded.rate._embedded.deliveryService._links.self.href.split('/')
        deliveryService = Number(parts[parts.length - 1])
      }

      const print = (this.appOptions.multiprintService || []).includes(deliveryService)
          ? {
            id: 'print-dropdown',
            wrapperClassName: 'col-3 q-px-xs',
            wrapperStyle: 'max-width: 100px;',
            type: 'dropdown',
            variant: 'primary',
            label: this.$t('Print'),
            options: [
              {
                id: 'print-label',
                type: 'button',
                icon: 'print',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Print label'),
                onClick: this.printLabelByRequest
              },
              {
                id: 'print-a5',
                type: 'button',
                icon: 'print',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('A5'),
                onClick: () => {
                  this.printLabelByRequestBySize({ pageSize: 'A5' })
                }
              },
              {
                id: 'print-a6',
                type: 'button',
                icon: 'print',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('A6'),
                onClick: () => {
                  this.printLabelByRequestBySize({ pageSize: 'A6' })
                }
              }
            ]
          }
          : {
            id: 'print',
            wrapperClassName: 'col-3 q-px-xs',
            wrapperStyle: 'max-width: 100px;',
            type: 'button',
            icon: 'print',
            variant: 'light',
            disabled: !this.deliveryRequest || !this.deliveryRequest.id,
            label: this.$t('Print label'),
            onClick: () => {
              this.printLabelByRequest()
            }
          }

      const actions = {
        section: 'Actions',
        className: 'col-12 col-sm text-center row items-center q-gutter-y-xs q-py-xs no-drop',
        options: [
          {
            id: 'services-dropdown',
            type: 'dropdown',
            label: '•••',
            options: [
              print,
              {
                id: 'calculator',
                type: 'button',
                icon: 'calculate',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Calculator'),
                onClick: this.handleOpenModal
              },
              {
                if: this.hasBaseAccess,
                id: 'export',
                type: 'button',
                icon: 'import_export',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !['pre_processing', 'pending'].includes(this.deliveryRequest.state),
                label: this.$t('Create Label'),
                onClick: this.handleExport
              },
              {
                id: 'task',
                icon: 'task',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Shipping task'),
                onClick: () => {
                  const query = {
                    per_page: 25,
                    filter: [
                      { field: 'deliveryRequest', type: 'eq', value: this.deliveryRequest.id },
                    ]
                  }

                  this.loadPreprocessingTaskQuery(query)
                      .then((task) => {
                        if (task.items) {
                          this.$refs.shipmentTaskModal.open(task.items[0])
                        }
                        this.isRequestedTask = true
                      })
                }
              },
              {
                id: 'trackingsNumber',
                type: 'button',
                variant: 'light',
                icon: 'gps_not_fixed',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !!this.deliveryRequest.trackingNumber,
                label: this.$t('Tracking number'),
                onClick: () => {
                  this.$refs.trackingNumberModal.show(this.deliveryRequest)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services/Upload PDF'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'order',
                type: 'button',
                icon: 'sell',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav || !this.deliveryRequest.eav['delivery-request-products-order'],
                variant: 'light',
                label: this.$t('Order'),
                onClick: () => {
                  this.$router.push(`${this.prefix}/orders/entity/unknown/${this.deliveryRequest.eav['delivery-request-products-order']}`)
                }
              },
              {
                id: 'places',
                type: 'button',
                icon: 'store',
                variant: 'light',
                label: this.$t('Products/Places'),
                onClick: this.showPlaces
              },
              {
                id: 'eav',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav,
                label: this.$t('EAV'),
                onClick: () => {
                  const options = {
                    entityClass: this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest
                  }

                  this.$refs.eavModal.open(this.deliveryRequest.eav, options)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'history',
                type: 'button',
                icon: 'history',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('History'),
                onClick: this.handleHistory
              },
              {
                id: 'chat',
                type: 'button',
                icon: 'comments',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Chat'),
                onClick: () => {
                  const entities = [
                    {
                      entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
                      entity: this.deliveryRequest.id
                    }
                  ]

                  this.$refs.chatModal.open(entities)
                }
              },
              {
                id: 'template',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Documents'),
                onClick: this.handleGetDocument
              },
              {
                id: 'lock',
                icon: this.isLockedDR ? 'lock-open' : 'lock',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.isLockedDR,
                label: this.$t(this.isLockedDR ? 'Unlock' : 'Lock'),
                onClick: this.handleUnlock
              }
            ]
          },
          {
            id: 'beta',
            wrapperStyle: 'max-width: 60px;',
            style: 'font-size: 10px!important;font-weight: bold;padding: 0;',
            wrapperClassName: 'col-4 q-px-sm beta',
            type: 'button',
            label: 'S',
            color: this.beta,
            size: 'sm',
            onClick: () => {
              this.$router.push(`/outbound/shipments/entity/${this.deliveryRequest.id}`)
            }
          }
        ]
      }

      const actionsSimple = {
        section: 'Actions',
        className: 'col-12 col-sm row q-pb-xs no-drop',
        options: [
          {
            id: 'services-dropdown',
            type: 'dropdown',
            label: '•••',
            options: [
              print,
              {
                id: 'calculator',
                type: 'button',
                icon: 'calculate',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Calculator'),
                onClick: this.handleOpenModal
              },
              {
                if: this.hasBaseAccess,
                id: 'export',
                type: 'button',
                icon: 'import_export',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !['pre_processing', 'pending'].includes(this.deliveryRequest.state),
                label: this.$t('Create Label'),
                onClick: this.handleExport
              },
              {
                id: 'task',
                icon: 'task',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Shipping task'),
                onClick: () => {
                  const query = {
                    per_page: 25,
                    filter: [
                      { field: 'deliveryRequest', type: 'eq', value: this.deliveryRequest.id },
                    ]
                  }

                  this.loadPreprocessingTaskQuery(query)
                      .then((task) => {
                        if (task.items) {
                          this.$refs.shipmentTaskModal.open(task.items[0])
                        }
                        this.isRequestedTask = true
                      })
                }
              },
              {
                id: 'trackingsNumber',
                type: 'button',
                variant: 'light',
                icon: 'gps_not_fixed',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !!this.deliveryRequest.trackingNumber,
                label: this.$t('Tracking number'),
                onClick: () => {
                  this.$refs.trackingNumberModal.show(this.deliveryRequest)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services/Upload PDF'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'order',
                type: 'button',
                icon: 'sell',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav || !this.deliveryRequest.eav['delivery-request-products-order'],
                variant: 'light',
                label: this.$t('Order'),
                onClick: () => {
                  this.$router.push(`${this.prefix}/orders/entity/unknown/${this.deliveryRequest.eav['delivery-request-products-order']}`)
                }
              },
              {
                id: 'places',
                type: 'button',
                icon: 'store',
                variant: 'light',
                label: this.$t('Products/Places'),
                onClick: this.showPlaces
              },
              {
                id: 'eav',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav,
                label: this.$t('EAV'),
                onClick: () => {
                  const options = {
                    entityClass: this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest
                  }

                  this.$refs.eavModal.open(this.deliveryRequest.eav, options)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'history',
                type: 'button',
                icon: 'history',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('History'),
                onClick: this.handleHistory
              },
              {
                id: 'template',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Documents'),
                onClick: this.handleGetDocument
              },
              {
                id: 'lock',
                icon: this.isLockedDR ? 'lock-open' : 'lock',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.isLockedDR,
                label: this.$t(this.isLockedDR ? 'Unlock' : 'Lock'),
                onClick: this.handleUnlock
              }
            ]
          },
          {
            id: 'beta',
            wrapperStyle: 'max-width: 60px;',
            style: 'font-size: 10px!important;font-weight: bold;padding: 0;',
            wrapperClassName: 'col-4 q-px-sm beta',
            type: 'button',
            label: 'L',
            color: this.beta,
            size: 'sm',
            onClick: () => {
              this.$router.push(`/shipments/entity/${this.deliveryRequest.id}`)
            }
          }
        ]
      }

      const pin = {
        section: 'LockPin',
        className: 'col-12 col-sm-1',
        style: 'max-width: 80px;',
        options: [
          {
            id: 'pin',
            wrapperStyle: 'max-width: 60px;',
            wrapperClassName: 'col-4 q-px-xs',
            type: 'button',
            icon: this.note.icon,
            color: this.note.color,
            size: 'sm',
            onClick: () => {
              const action = this.routeShortcuts.find(({ route }) => route === this.$route.fullPath)
                  ? 'removeRouteToShortcuts'
                  : 'addRouteToShortcuts'

              const data = {
                route: this.$route.fullPath,
                name: this.page.id,
                description: this.page.name
              }

              this[action](data)
            }
          }
        ]
      }

      return [
        {
          section: 'BackAction',
          className: 'hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-6 col-sm-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              additionalValueClassName: 'col-6 col-sm-12 q-px-xs',
              value: this.header,
              additionalValue: this.deliveryRequest && this.deliveryRequest.trackingNumber ? this.$t('#') + this.deliveryRequest.trackingNumber : null
            }
          ]
        },
        !this.simple ? actions : actionsSimple,
        dropdown,
        pin
      ]
    },
    isLockedDR () {
      return this.lockedDeliveryRequests.includes(this.deliveryRequest && this.deliveryRequest.id)
    },
    isDisabledFields () {
      let currentStateOptions = this.statuses[0].buttons[0]

      for (let i = 0; i < this.statuses.length; i++) {
        const group = this.statuses[i]
        const state = group.buttons.find(btn => btn.id === (this.deliveryRequest || {}).state)

        if (state) {
          currentStateOptions = state
          break
        }
      }

      if (currentStateOptions.id === 'pending_queued') {
        if (!this.deliveryRequest || !this.deliveryRequest.id) {
          return false
        }

        return !currentStateOptions.editableField
      }

      return !currentStateOptions.editableField || this.isLockedDR
    }
  },
  watch: {
    deliveryRequest: {
      handler (newValue) {
        if (newValue && newValue._embedded && newValue._embedded.rate) {
          const types = ['service_point', 'self_service_point']
          this.isCourier = !types.includes(newValue._embedded.rate.type)
        }
      },
      deep: true
    }
  },
  beforeMount () {
    if (this.deliveryRequest && this.deliveryRequest.id) {
      this.header = this.$t('Shipments')
      this.header += (' / <tlabel>#</tlabel>' + this.deliveryRequest.id)
      if (this.deliveryRequest.extId) {
        this.header += ' (<tlabel>ExtId</tlabel>' + this.deliveryRequest.extId + ')'
      }
    } else {
      this.header = this.$t('New shipment')
    }
  },
  mounted () {
    this.setHasDRChange(false)
    if (this.deliveryRequest && this.window) {
      this.isCourier = (this.deliveryRequest._embedded.rate && this.deliveryRequest._embedded.rate.type === 'simple') || !this.deliveryRequest._embedded.servicePoint
      return
    }

    if (!this.deliveryRequest && this.$route.params.id) {
      this.loadDeliveryRequest(this.$route.params.id)
          .then(() => {
            this.setHasDRChange(false)
            this.submitOpenRequest()
          })
    }

    if (this.deliveryRequest && this.$route.params.id) {
      this.submitOpenRequest()
    }

    if (!this.$route.params.id) {
      this.setNewDeliveryRequest()
    }

    if (this.deliveryRequest) {
      this.isCourier = (this.deliveryRequest._embedded.rate && this.deliveryRequest._embedded.rate.type === 'simple') || !this.deliveryRequest._embedded.servicePoint
    }
    this.isDisabledDomestic()

    if (this.appOptions.versionType === 'simple') {
      this.simple = true
    } else {
      this.prefix = '/outbound'
    }
  },
  unmounted () {
    this.setHasDRChange(false)
    if (this.deliveryRequest && this.deliveryRequest.id && !this.lockedDeliveryRequests.includes(this.deliveryRequest.id)) {
      this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
    }

    this.resetDeliveryRequest()
  },
  methods: {
    ...mapActions([
      'loadDeliveryServices',
      'loadRates',
      'loadDeliveryRequest',
      'saveDeliveryRequest',
      'printLabelByRequest',
      'loadServicePoints',
      'refreshDeliveryRequest',
      'loadSenders',
      'saveDeliveryRequestByPayload',
      'printLabelByRequestBySize',
      'loadPreprocessingTaskQuery',
    ]),
    ...mapMutations([
      'updateDeliveryRequest',
      'resetDeliveryRequest',
      'setNewDeliveryRequest',
      'addErrorNotification',
      'setDeliveryRequests',
      'removeLockedDeliveryRequest',
      'addLockedDeliveryRequest',
      'updateCleanDeliveryRequest',
      'updateDeliveryRequestEmbedded',
      'setHasDRChange',
      'addRouteToShortcuts',
      'removeRouteToShortcuts'
    ]),
    isDisabledDomestic () {
      const options = getRouteOptions('address')

      if (options.additionalProperties && options.additionalProperties.disabledFor) {
        const disabledFor = options.additionalProperties.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          this.disabledDomestic = true
          return
        }
      }

      return
    },
    handleTrackingNumber (dr) {
      this.updateDeliveryRequest({ trackingNumber: dr.trackingNumber })
      this.updateCleanDeliveryRequest({ trackingNumber: dr.trackingNumber })
    },
    handleGetDocument () {
      this.$refs.documentModal.open(this.order)
    },
    handleEAVSubmit (eav) {
      this.updateDeliveryRequest({ eav })
    },
    handlePlaces (places) {
      this.updateDeliveryRequest({ places })

      if (this.deliveryRequest.id) {
        this.updateCleanDeliveryRequest({ places })
      }
    },
    handleUnlock () {
      if (this.isLockedDR) {
        this.isManuallyLocked = false
        return this.removeLockedDeliveryRequest(this.deliveryRequest.id)
      }

      this.isManuallyLocked = true
      return this.addLockedDeliveryRequest(this.deliveryRequest.id)
    },
    submitOpenRequest () {
      if (this.lockedDeliveryRequests.includes(this.deliveryRequest.id)) {
        return
      }

      this.$channels.user.publish('openDeliveryRequest', this.deliveryRequest.id)
    },
    handleDeliveryServicesIds (ids) {
      this.deliveryServicesIds = ids
    },
    handleCurierChange () {
      this.isCourier = !this.isCourier
    },
    handleLocalityChange (locality) {
      this.updateEmbedded({
        recipientLocality: locality,
        recipientAddress: {
          ...this.deliveryRequest._embedded.recipientAddress,
          _embedded: {
            ...this.deliveryRequest._embedded.recipientAddress._embedded,
            locality: locality
          }
        }
      })
    },
    handleUpdateSender (sender) {
      this.updateEmbedded({ sender })
    },
    showPlaces () {
      this.$refs.productsModal.open(this.deliveryRequest.id, this.deliveryRequest.places, !!(this.deliveryRequest.eav || {})['delivery-request-products-order'])
    },
    refresh () {
      this.loadDeliveryRequest(this.$route.params.id)
    },
    handleLocality (locality) {
      if (
          locality.id &&
          (locality.postcode && (this.deliveryRequest._embedded.recipientAddress || {}).postcode !== locality.postcode)
      ) {
        const recipientAddress = (this.deliveryRequest._embedded.recipientAddress || { _embedded: {} })

        if (!recipientAddress._embedded) {
          recipientAddress._embedded = {}
        }

        recipientAddress._embedded.locality = locality

        this.updateEmbedded({
          recipientAddress: {
            ...recipientAddress,
            postcode: locality.postcode
          },
          recipientLocality: locality
        })
      }
    },
    updateServicePoint (servicePoint) {
      this.updateEmbedded({ servicePoint })

      if (servicePoint && servicePoint._embedded) {
        const locality = servicePoint._embedded.locality
        this.handleLocality(locality)
      }

      this.calculatorModal = false
    },
    handleHistory () {
      this.historyModal = true
    },
    handleOpenModal () {
      this.calculatorModal = true
    },
    handleExport () {
      this.$refs.exportModal.open(this.deliveryRequest)
    },
    handleDiscard () {
      if (this.window) {
        this.$emit('close')
        return
      }

      this.$router.go()
    },
    handleBack () {
      if (this.window) {
        this.$emit('close')
        return
      }

      this.$router.back()
    },
    updateDimensions (dimensions) {
      this.updateDeliveryRequest(dimensions)
      this.calculatorModal = false
    },
    updateProfile (recipient) {
      if (!recipient.phone) {
        this.updateDeliveryRequest({
          _embedded: {
            ...this.deliveryRequest._embedded,
            recipient
          },
          recipientName: recipient.name,
        })
      } else {
        this.updateDeliveryRequest({
          _embedded: {
            ...this.deliveryRequest._embedded,
            recipient
          },
          recipientName: recipient.name,
          recipientPhone: recipient.phone
        })
      }

    },
    updateRecipientAddress (address) {
      this.updateEmbedded({
        recipientAddress: address,
        recipientLocality: address._embedded.locality
      })
    },
    updateRate (rate, isDimensions) {
      this.updateDeliveryRequestEmbedded({ rate, deliveryService: rate.deliveryService, servicePoint: null })

      if (rate.raw) {
        this.updateDeliveryRequest({
          eav: {
            ...this.deliveryRequest?.eav,
            'delivery-services-calculation-result': rate.raw
          }
        })
      }

      // if(!isDimensions) {
      //    this.calculatorModal = isDimensions
      // }
    },
    updatePhone (recipientPhone) {
      this.updateEmbedded({ recipientPhone })
    },
    updateEmbedded (update) {
      this.updateDeliveryRequest({
        _embedded: {
          ...this.deliveryRequest._embedded,
          ...update
        }
      })
    },
    makeSaveDeliveryRequest () {
      const error = this.$utils.validate.deliveryRequest(this.deliveryRequest, !this.isInternational)

      if (error) {
        this.addErrorNotification(error)
        return
      }

      const payload = {
        redirectLink: undefined
      }

      if (!this.deliveryRequest.id) {
        payload.redirectLink = this.prefix + '/shipments'
      }

      const isNewDeliveryRequest = !this.deliveryRequest.id

      this.isSaveLoading = true
      this.saveDeliveryRequest(payload)
          .then(item => {
            if (isNewDeliveryRequest) {
              this.setDeliveryRequests([item, ...this.deliveryRequests])
            }

            if (this.window) {
              this.$emit('submit', item)
            }

            this.setHasDRChange(false)
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },

  }
}
</script>

<style>
.beta .col {
  font-size: 16px;
}
</style>